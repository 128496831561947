import React, { useEffect, useMemo, useRef, useContext } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import RegionsDataService from "../../services/RegionService";
import { RegionsContext } from '../../App';
import { useTable } from "react-table";

const RegionsList = (props) => {

  const location = useLocation();

  const RegionsRef = useRef();

  const { regions, setRegions } = useContext(RegionsContext);

  RegionsRef.current = regions;

  useEffect(() => {
//    retrieveRegions();
  }, []);

  const addRegionLink = {
    pathname: '/add/region',
    state: { 
      returnUrl: '/regions'
    }
  };

  const openRegion = (rowIndex) => {
    const id = RegionsRef.current[rowIndex].id;
    props.history.push({
      pathname: "/regions/" + id,
      state: {}});
  };

  const deleteRegion = (rowIndex) => {
    const id = RegionsRef.current[rowIndex].id;
    if ( window.confirm("是否確認刪除 ?") ){
      RegionsDataService.remove(id)
        .then((response) => {
          props.history.push({
            pathname: "/regions/",
            state: {}});
          let newRegions = [...RegionsRef.current];
          newRegions.splice(rowIndex, 1);
          setRegions(newRegions);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",        
      },
      {
        Header: "名稱",
        accessor: "name",
      },
      {
        Header: "功能",
        accessor: "actions",
        maxWidth: 60,
        minWidth: 60,
        width: 60,
        Cell: ({row}) => {
          const rowIdx = row.id;
          return (
            <div className="inline-actions">
              <div onClick={() => openRegion(rowIdx)}>
                <button><i className="far fa-edit action mr-2"></i> 編輯</button>
              </div>
              <div onClick={() => deleteRegion(rowIdx)}>
                <button className="delete"><i className="far fa-trash-alt mr-2"></i> 移除</button>
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regions]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: regions ? regions : [],
  });

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-4 list">
          <h2>區域清單</h2>
        </div>
        <div className="col-md-2 list">
        <Link to={addRegionLink}>
          <button className="btn btn-success">
          <i className="far fa-plus-square action mr-2"></i> 新增
          </button>
        </Link>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-6 list">
          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RegionsList);

import React, {useState} from 'react';
import { Link } from "react-router-dom";
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
// import {isMobile} from 'react-device-detect';

import { SideBarData } from './SideBarData';
import authService from '../api-authorization/AuthorizeService'
import { LoginMenu } from '../api-authorization/LoginMenu';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import './Navbar.css';

function NavbarAll() {
    const [sidebar, setSidebar] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const showSideBar = () => {
        authService.isAuthenticated().then(res => {
            (res) ? setSidebar(!sidebar) : setSidebar(false);
        });
    };
    const toggleNavbar = () => {
        setNavbar(!navbar);
    }
    
    const isDevelopment = () => {
        let retVal = (window.location.hostname !== "admin.happinessvillage.org");
        return retVal;
    } 

    return (
        <div>
            <div className={`navbar${isDevelopment()? " dev": ""}`} >
                <Navbar 
                    className={`navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3`} 
                    light>
                    <Container>
                            <FaIcons.FaBars onClick={showSideBar} className="menu-bars"/>
                        <NavbarBrand 
                            tag={Link} 
                            to="/users">
                                芯福里情緒教育推廣協會 
                                { isDevelopment() ? <span style={{fontSize:"small", color:"red"}}>測試站</span> : <></>}
                        </NavbarBrand>
                        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={navbar} navbar>
                        <ul className="navbar-nav flex-grow">
                            <LoginMenu></LoginMenu>
                        </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav-menu-items' onClick={showSideBar}>
                    <li className='navbar-toggle'>
                        <Link to="#" className="menu-bars">
                            <AiIcons.AiOutlineClose/>
                        </Link>
                    </li>
                    {SideBarData.map((item, index) => {
                        return (
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </div>
    )
}

export default NavbarAll

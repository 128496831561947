import React, { useState, useEffect } from "react";
import UserDataService from "../../services/UserService";

const User = (props) => {
  const initialUserState = {
    id: null,
    title: "",
    email: "",
    name: "",
    phone: "",
    altContact: "",
    note: ""
  };
  const [currentUser, setCurrentUser] = useState(initialUserState);
  const [message] = useState("");

  const getUser = id => {
    UserDataService.get(id)
      .then(response => {
        var user = response.data;
        Object.keys(user).forEach(function (key, index) {
          if (user[key] == null) { user[key] = ""; }
        });
        setCurrentUser(user);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUser(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
    console.log(currentUser);
  };

  const updateUser = () => {
    UserDataService.update(currentUser.id, currentUser)
      .then(response => {
        props.history.goBack();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div>
      {currentUser ? (
        <div className="edit-form">
          <h4>學員</h4>
          <form>
            <div className="form-group">
              <label htmlFor="name">姓名</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={currentUser.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">電子信箱</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={currentUser.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">手機</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={currentUser.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="altContact">其他聯絡方式</label>
              <input
                type="text"
                className="form-control"
                id="altContact"
                name="altContact"
                value={currentUser.altContact}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="note">備註</label>
              <input
                type="text"
                className="form-control"
                id="note"
                name="note"
                value={currentUser.note}
                onChange={handleInputChange}
              />
            </div>
          </form>

          <p style={{marginTop:'12px'}}>
          <button type="submit" className="btn btn-success" onClick={updateUser}>
              更新資料
          </button>
          </p>

          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          {/* <p>Please click on a User...</p> */}
        </div>
      )}
    </div>
  );
};

export default User;

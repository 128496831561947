import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import SchoolDataService from "../../services/SchoolService";
import { RegionsContext, DistrictsContext, CitiesContext } from '../../App';
import { useTable, usePagination } from "react-table";

import Excel from "exceljs";

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
};

const SchoolList = (props) => {
  const location = useLocation();
  const cacheTitle = props.location.state?.cacheTitle || location.state?.cacheTitle;

  const [Schools, setSchools] = useState([]);
  const [searchTitle, setSearchTitle] = useState(cacheTitle ? cacheTitle : "");
  const [searchRegion, setSearchRegion] = useState(0);

  const SchoolRef = useRef();
  const SearchTitleRef = useRef();

  const { regions } = useContext(RegionsContext);
  const { cities } = useContext(CitiesContext);
  const { districts } = useContext(DistrictsContext);

  SchoolRef.current = Schools;
  SearchTitleRef.current = searchTitle;

  useEffect(() => {
    retrieveSchools();
  }, [searchRegion, regions, cities, districts]);

  const retrieveSchools = () => {
    SchoolDataService.getAll()
      .then(({ data }) => {
        if (data.total > 0)
          if( regions == null || cities == null || districts == null ){ return false; }
          setSchools(data.items.filter(item=>{
            let regionName = regions?.find(x => x.id === item.regionId)?.name;
            let district = districts?.find(x => x.id === item.districtId);
            let cityName = cities?.find(x => x.id === district?.cityId)?.name;
            // filter with side effect, items.forEach().filter() would be better
            item.region = regionName;
            item.district = district.name;
            item.city = cityName;
            return ((searchRegion !== 0) ? item.regionId === searchRegion : true) &&
                    (item.name.includes(searchTitle) ||
                    item.address?.includes(searchTitle) ||
                    district?.name.includes(searchTitle) ||
                    cityName?.includes(searchTitle))
          }));
      }).catch(e => console.log(e));
  };

  const addSchoolLink = {
    pathname: '/school/',
    state: {
      cacheTitle: searchTitle,
      returnUrl: '/schools'
    }
  };

  const exportExcel = () => {
    const workbook = new Excel.Workbook();
    workbook.creator = "線上課程平台";
    workbook.lastModifiedBy = "線上課程平台";
    workbook.created = new Date();
    workbook.modified = new Date();
    var worksheet = workbook.addWorksheet("學校資料");
    worksheet.columns = [
      { header: '#', key: 'id', width: 10 },
      { header: '學校代碼', key: 'code', width: 12 },
      { header: '學校名稱', key: 'name', width: 32 },
      { header: '協會區域', key: 'region', width: 12 },
      { header: '縣/市', key: 'city', width: 12 },
      { header: '鄉鎮區', key: 'district', width: 12 },
      { header: '地址', key: 'address', width: 60 },
      { header: '電話', key: 'phone', width: 20 },
      { header: '網站', key: 'url', width: 100 }
    ];
    Schools.forEach( x=> worksheet.addRow(x) );
    const row = worksheet.getRow(1);
    row.eachCell((cell, rowNumber) => {
      worksheet.getColumn(rowNumber).alignment = {
        vertical: "middle",
        horizontal: ([3,7,9].includes(rowNumber)) ? "left" : "center"
      };
    });
    worksheet.getRow(1).eachCell((cell, rowNumber) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(`${workbook.created.toISOString().substring(0,10)}.學校清單匯出.xlsx`, buffer);
    });
  }

  const writeFile = (fileName, content) => {
    let a = document.createElement("a");
    let blob = new Blob([content], { type: "text/plain" });
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  };

  const openSchool = (rowIndex) => {
    const id = SchoolRef.current[rowIndex].id;
    console.log(`Bring to next page ${SearchTitleRef.current}`);

    props.history.push({
      pathname: "/school/" + id,
      state: {
        cacheTitle: SearchTitleRef.current,
        returnUrl: '/schools'
      }
    });
  };

  const deleteSchool = (rowIndex) => {
    const id = SchoolRef.current[rowIndex].id;

    if (window.confirm("是否確認刪除 ?")) {
      SchoolDataService.remove(id)
        .then((response) => {
          let newSchools = [...SchoolRef.current];
          newSchools.splice(rowIndex, 1);
          setSchools(newSchools);
        })
        .catch((e) => {
          alert('目前有學員以此學校報名，無法刪除');
          console.log(e);
        });
    };
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "分區",
        Cell: ({ row }) => {
          let { regionId } = row.original
          let region = regions.find(x => x.id === regionId);
          return <>
            {(region) ? <div>{region.name}</div> : <></>}
          </>;
        }
      },
      {
        Header: "名稱",
        accessor: "name",
      },
      {
        Header: "聯絡方式",
        Cell: ({ row }) => {
          let { address, phone, url } = row.original;
          return <>
            {(address) ? <div>地址：{address}</div> : <></>}
            {(phone) ? <div>電話：{phone}</div> : <></>}
            {(url) ? <div>網站：<a href={url} target="blank">{url}</a></div> : <></>}
          </>;
        }
      },
      {
        Header: "功能",
        accessor: "actions",
        Cell: ({ row }) => {
          const rowIdx = row.id;
          return (
            <div className="actions">
              <div onClick={() => openSchool(rowIdx)}>
                <button><i className="far fa-edit action mr-2"></i> 編輯學校資訊</button>
              </div>
              <div onClick={() => deleteSchool(rowIdx)}>
                <button className="delete"><i className="far fa-trash-alt mr-2"></i> 移除學校</button>
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regions]
  );
  
  const handlePressEnter = (e) => {
    console.log(e);
    if (e.key === 'Enter') {
      retrieveSchools();
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: Schools,
    initialState: { pageIndex: 0 },
  }, usePagination);

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-10 list">
          <h2>學校管理</h2>
        </div>
        <div className="col-2 list">
          <Link to={addSchoolLink}>
            <button className="btn btn-success">
              <i className="far fa-plus-square action mr-2"></i> 新增
            </button>
          </Link>
        </div>
      </div>
      <div className="list row align-items-end">
        <div className="col-md-4">
          <div className="form-group">
          <label>分區：</label>
            <select className="form-control"
              name="regionId"
              value={searchRegion}
              onChange={e=>setSearchRegion(parseInt(e.target.value))}>
              {
                (regions) ? [<option key="0" value="0">--</option>].concat(
                  regions.sort((a,b)=> { return (a.id > b.id) ? 1 : (a.id === b.id) ? 0 : -1 }).map(x => <option key={x.id} value={x.id}>{x.name}</option>))
                  : <></>
              }
            </select>  
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>關鍵字搜尋：</label>
            <input autoFocus 
              type="text"
              className="form-control"
              placeholder="查詢校名、地址、縣市、行政區"
              value={searchTitle}
              onChange={e=>setSearchTitle(e.target.value)}
              onKeyPress={handlePressEnter}
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={retrieveSchools}>搜尋</button>
          </div>
        </div>
      </div>
      <div className="list row top10">
        <div className="col-md-12 list">
          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-around align-items-center" style={{height: 80}}>
            <div className="pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}> {'<<'} </button>{' '}
              <button onClick={() => previousPage()} disabled={!canPreviousPage}> {'<'} </button>{' '}
              <button onClick={() => nextPage()} disabled={!canNextPage}> {'>'} </button>{' '}
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}> {'>>'} </button>{' '}
              <span> 第{' '} <strong> {pageIndex + 1} / {pageOptions.length} </strong>{' '} 頁 </span>
              <span>
                | 跳至頁數:{' '}
                <input
                  type="number"
                  min="1"
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '}
              <select
                value={pageSize}
                onChange={e => { setPageSize(Number(e.target.value)) }}
              >
                {[10, 20, 50, 100].map(pageSize => ( <option key={pageSize} value={pageSize}> 每頁 {pageSize} 筆 </option> ))}
              </select>
              <span> 共{' '} <strong>{Schools.length}</strong>{' '} 筆 </span> {' '} 
            </div>
            <button onClick={() => exportExcel()}> 匯出 Excel </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SchoolList);

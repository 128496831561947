import React, { useState } from "react";
import {useLocation, useHistory} from "react-router-dom";
import ServiceRoleDataService from "../../services/ServiceRoleService";

const AddServiceRole = () => {
  const history = useHistory();
  const location = useLocation();

  const cacheTitle = location.state?.cacheTitle;
  const returnUrl = location.state?.returnUrl;
  
  const returnServiceRoleLink = {
    pathname: returnUrl,
    state: {
      cacheTitle: cacheTitle
    }
  }

  const initialServiceRoleState = {
    id: null,
    name: ""
  };
  const [serviceRole, setServiceRole] = useState(initialServiceRoleState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setServiceRole({ ...serviceRole, [name]: value });
  };

  const returnLastPage = () => {
    history.push(returnServiceRoleLink);
  };

  const saveServiceRole = () => {
    var data = {
      name: serviceRole.name
    };

    ServiceRoleDataService.create(data)
      .then(response => {
        setServiceRole({
          id: response.data.id,
          name: response.data.name
        });
        setSubmitted(true);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    returnLastPage();
  };

  return (
    <div className="submit-form">
      {submitted ? (
        {}
      ) : (
        <div>
          <div className="form-group">
            <label htmlFor="name">名稱</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={serviceRole.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>

          <button onClick={saveServiceRole} className="btn btn-success">
            提交
          </button>
        </div>
      )}
    </div>
  );
};

export default AddServiceRole;

import http from "../http-common";

const repoPath = '/cities';

const getAll = () => {
  return http.get(repoPath);
};

const get = (id) => {
  return http.get(`${repoPath}/${id}`);
};

const create = (data) => {
  return http.post(repoPath, data);
};

const update = (id, data) => {
  return http.put(`${repoPath}/${id}`, data);
};

const remove = (id) => {
  return http.delete(`${repoPath}/${id}`);
};

const CityService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default CityService;

import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import SchoolDataService from "../../services/SchoolService";
import { RegionsContext } from "../../App";
import { DistrictsContext } from "../../App";
import { CitiesContext } from "../../App";

const School = (props) => {
  const cacheTitle = props.location.state?.cacheTitle;
  console.log(`Last Info - props ${props.location.state?.cacheTitle}`);

  const initialSchoolState = {
    id: null,
    name: "",
    code: "",
    address: "",
    phone: "",
    url: "",
    regionId: 9,
    cityId: 372,
    districtId: 369
  };
  const [currentSchool, setCurrentSchool] = useState(initialSchoolState);
  const [message, setMessage] = useState("");

  const { regions } = useContext(RegionsContext);
  const { cities } = useContext(CitiesContext);
  const { districts } = useContext(DistrictsContext);

  const getSchool = id => {
    SchoolDataService.get(id)
      .then(response => {
        let schoolData = response.data;
        schoolData.cityId = schoolData.districtId
          ? districts.find(x => x.id == schoolData.districtId).cityId
          : initialSchoolState.cityId;
        setCurrentSchool(schoolData);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (props.match.params.id) {
      getSchool(props.match.params.id);
    } else {
      setCurrentSchool(initialSchoolState);
    }
  }, [props.match.params.id, districts]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentSchool({ ...currentSchool, [name]: value });
    console.log(" change : " + name + " --> " + value);
  };

  function updateSchool() {
    var result;
    if (currentSchool.id == null) {
      delete currentSchool.id;
      result = SchoolDataService.create(currentSchool);
    } else {
      result = SchoolDataService.update(currentSchool.id, currentSchool);
    };
    result
      .then(response => {
        console.log(response.data);
        setMessage("學校內容更新！");
        props.history.push({
          pathname: "/schools",
          state: {
            cacheTitle: cacheTitle
          }
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <div>
      {currentSchool ? (
        <div className="edit-form">
          <h4>學校</h4>
          <form>
          <div className="form-group">
              <label htmlFor="name">學校代碼</label>
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                value={currentSchool.code}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">名稱</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={currentSchool.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">電話</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={currentSchool.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">地址</label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                value={currentSchool.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">網址</label>
              <input
                type="text"
                className="form-control"
                id="url"
                name="url"
                value={currentSchool.url}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-inline">
              <div className="form-group col-lg-12">
                <label>縣市/行政區</label>
                <select 
                  className="form-control"  
                  name="cityId" value={currentSchool.cityId} onChange={handleInputChange}>
                  {
                    (cities) ?
                      cities.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                      : <></>
                  }
                </select>
                <select
                  className="form-control"
                  name="districtId" value={currentSchool.districtId} onChange={handleInputChange}>
                  {
                    (districts) ?
                      districts
                        .filter(district => district.cityId == currentSchool.cityId)
                        .map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                      : <></>
                  }
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="regionSelect" >區域</label>
              <select className="form-control" name="regionId" value={currentSchool.regionId} onChange={handleInputChange} id="regionSelect">
                {
                  (regions) ?
                    regions.map(x => <option key={x.id} value={x.id}>{x.name}</option>)
                    : <></>
                }
              </select>
            </div>

          </form>

          <button
            type="submit"
            className="btn btn-success"
            onClick={updateSchool}
          >
            更新
          </button>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          {/* <p>Please click on a School...</p> */}
        </div>
      )}
    </div>
  );
};

export default withRouter(School);

import React, { useState } from "react";
import {useLocation, useHistory} from "react-router-dom";
import RegionDataService from "../../services/RegionService";

const AddRegion = () => {
  const history = useHistory();
  const location = useLocation();

  const returnUrl = location.state?.returnUrl;
  
  const returnRegionLink = {
    pathname: returnUrl,
    state: { }
  }

  const initialRegionState = {
    id: null,
    name: "",
  };

  const [region, setRegion] = useState(initialRegionState);
  
  const handleInputChange = event => {
    const { name, value } = event.target;
    setRegion({ ...region, [name]: value });
  };

  const returnLastPage = () => {
    history.push(returnRegionLink);
  };

  const saveRegion = () => {
    var data = {
      name: region.name,
    };
    RegionDataService.create(data)
      .then(response => {
        RegionDataService.getAll()
        .then((response) => {
//          setRegions(response.data);
        })
      })
      .catch(e => {
        console.log(e);
      });
    returnLastPage();
  };

  return (
    <div className="submit-form">
        <div>
          <div className="form-group">
            <label htmlFor="name">名稱</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={region.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>
          <button onClick={saveRegion} className="btn btn-success">
            提交
          </button>
        </div>
    </div>
  );
};

export default AddRegion;

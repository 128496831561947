import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import CitiesDataService from "../../services/CityService";
import DistrictsCell from "./Districts";
import { DistrictsContext } from "../../App";
import { useTable } from "react-table";
import './style.css';

const CitiesList = (props) => {

  const [Cities, setCities] = useState([]);
  const CitiesRef = useRef();

  const { districts }  = useContext(DistrictsContext);

  CitiesRef.current = Cities;
  
  useEffect(() => {
    retrieveCities();
  }, []);

  const retrieveCities = () => {
    CitiesDataService.getAll()
      .then((response) => {
        setCities(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",        
      },
      {
        Header: "城市",
        Cell: ({row}) => { 
          try { 
            return <span className="city">{row.original.name}</span>;
          } catch (err){
            return <>TBD</>
          }
        }
      },
      {
        Header: "鄉鎮區",
        Cell: ({row}) => { 
          try { 
            return <DistrictsCell cityId={row.original.id}/>;
          } catch (err){
            console.log(err);
            return <>TBD</>
          }
        }
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [districts]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: Cities,
  });

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-6 list">
          <h2>城市/鄉鎮區</h2>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-10 list">
          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CitiesList);

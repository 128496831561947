import http from "../http-common";
const getAll = () => {
  return http.get(`/users?page_size=5000`);
};
const get = (id) => {
  return http.get(`/users/${id}`);
};
const create = (data) => {
  return http.post("/users", data);
};
const update = (id, data) => {
  return http.put(`/users/${id}`, data);
};
const remove = (id) => {
  return http.delete(`/users/${id}`);
};
const findByKeyword = (keyword, classId, regionId) => {
  return http.get(`/users?keyword=${keyword}${classId?("&class="+classId):""}`
    + `${regionId === "0" ? "" : ("&region="+regionId)}&page_size=5000`);
};
const findByClass = (classId) => {
  return http.get(`/users?class=${classId}&page_size=5000`);
};
const reportByUserClass = (userId, classId) => {
  return http.get(`/UserClassReport?${(classId ? "class="+classId+"&":"")}user=${userId}`);
};

const UserService = {
  getAll,
  get,
  create,
  update,
  remove,
  findByKeyword,
  findByClass,
  reportByUserClass
};
export default UserService;
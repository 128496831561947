import React from 'react'
import axios from 'axios';
import './style.css';
import ClassDataService from "../../services/ClassService";

import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";

export function ExcelErrors(props) {

  const toLetters = (num) => {
    var mod = num % 26,
      pow = num / 26 | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
    return pow ? this.toLetters(pow) + out : out;
  };

  const toColName = (num) => {
    var colId = toLetters(num);
    switch (colId) {
      case "A":
        return "報名區域";
      case "B":
        return "縣市";
      case "C":
        return "鄉鎮區";
      case "D":
        return "註冊學校";
      case "E":
        return "姓名";
      case "F":
        return "電話";
      case "G":
        return "手機";
      case "H":
        return "Email";
      case "K":
        return "保證金效期";
      case "J":
        return "已付金額";
      case "N":
        return "組別";
      default:
        return toLetters(num);
    }
  };
  // ccy
  const mapErrorMessage = (msg, extra) => {
    switch (msg) {
      case "String not match":
        return [isEmpty(extra.new), isEmpty(extra.old), "資料差異"];
      case "Date expected":
        return ["", "", "需為合格日期格式"];
      case "Number expected":
        return ["", "", "需為合格數字格式"];
      case "String expected":
        return ["", "", "需為合格文字格式"];
      case "Already registered":
        return [extra.userName, "", " 帳號 " + extra.email + " 已報名本場次培訓"];
      case "School not found":
        return [extra.schoolName, "", "查無學校 (可能縣、市填寫錯誤)"];
      case "Mobile exists":
        return [extra.mobile, extra.mobile, "手機號碼已存在，請檢查是否重複報名 (可能家屬共用手機)"];
      case "User name-phone exists":
        return [extra.userName + "-" + extra.mobile, "", "[姓名-手機] 重複；可能同一位志工以不同 email 嘗試報名。"];
      case "District not found":
        return [extra.districtName, "", "查無行政區"];
      case "City not found":
        return [extra.cityName, "", "查無城市"];
      case "Region not found":
        return [extra.regionName, "", "查無協會區域"];
      default:
        return ["", "", "警示訊息：" + msg];
    }
  }

  function isEmpty(text) {
    return text == null ? "(未填)" : text;
  }

  // "group by" the Row ID (合併同一報名紀錄的錯誤項目)
  const errors = (props.errors != null && props.errors.reduce) ?
    props.errors.reduce(
      (r, err) => {
        const { message, column, row, extra } = err;
        r[row] = [...r[row] || [], {
          "col": toColName(column),
          "data": mapErrorMessage(message, extra)
        }]
        console.log(r);
        return r;
      }, []
    ) : [];


  // ccy

  return <div className="container" style={{ margin: "12px" }}>
    {(errors.length > 0) ? <><div className="row"><div className="col"><h4>匯入失敗、以下問題待修正</h4></div></div>
      <table>
        <tbody>
          <tr><th>項次</th><th>欄位</th><th>匯入資料</th><th>舊有資料</th><th>說明</th></tr>
          {errors.map((x, idx) => {
            return x.map((err, err_id) =>
              <tr key={err_id}>
                <td>{err_id > 0 ? <></> : <>#{idx}</>}</td>
                <td>{err.col}</td>
                <td>{err.data[0]}</td>
                <td>{err.data[1]}</td>
                <td>{err.data[2]}</td>
              </tr>
            );
          })}
        </tbody>
      </table></>
      : <></>
    }
  </div >;
}

class UploadExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      classData: null,
      file: null,
      overwrite: false,
      uploadMsg: "",
      errors: null,
      loading: false
    }
    this.uploadRef = React.createRef()
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
    this.onChangeOverwrite = this.onChangeOverwrite.bind(this)
  }

  componentDidMount() {
    ClassDataService.get(this.state.id).then(response => {
      this.setState({ classData: response.data });
      console.log(response.data);
    });
  }

  onFormSubmit(e) {
    this.setState({ loading: true, errors: null });
    e.preventDefault() // Stop form submit
    this.fileUpload(this.state.file, this.state.overwrite)
      .then((response) => {
        this.setState({ uploadMsg: "成功上傳", loading: false, errors: null });
      })
      .catch(e => {
        if (e.isAxiosError && e.response === undefined && e.message === "Network Error") {
          console.log(e.message);
          this.uploadRef.current.value = "";
          this.setState({ errors: null, uploadMsg: "因系統安全考量。檔案內容更新後，請重新選取要上傳的檔案。", loading: false });
        } else if (e.response.status === "500") {
          this.setState({ errors: null, uploadMsg: "檔案格式錯誤，請聯絡技術支援檢查範本格式。", loading: false });
          console.log(e.response.data);
        } else {
          var responseData = e.response.data;
          if (e.response.status === "400" && responseData.title) {
            this.setState({ errors: null, uploadMsg: "上傳錯誤，可能未挑選檔案。", loading: false });
          } else {
            console.log(responseData);
            this.setState({ errors: responseData, uploadMsg: "其他錯誤，請聯絡開發者排除問題", loading: false });
          }
        }
      })
  }

  onChange(e) {
    this.setState({ file: e.target.files[0] })
  }

  onChangeOverwrite() {
    this.setState({ overwrite: !this.state.overwrite })
  }

  fileUpload(file, overwrite) {
    const url = 'api/classes/' + this.state.id + '/registrations?overwrite=' + (overwrite ? 'true' : 'false');
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return axios.post(url, formData, config);
  }

  render() {
    const { classData } = this.state;
    return (
      <div>
        <form onSubmit={this.onFormSubmit} className="form-inline">
          <div className="container">
            <div className="row top10">
              <div className="col-sm">
                <h2>{(classData) ? classData.name : ""} 報名資料上傳</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <input type="file" ref={this.uploadRef} id="customFile" onChange={this.onChange} />
                <h4 style={{ color: "red", margin: "12px" }}>{this.state.uploadMsg}</h4>
              </div>
              <div className="col">
                <label><input
                  type="checkbox" value="overwrite"
                  onChange={this.onChangeOverwrite} checked={this.state.overwrite} /> &nbsp; 檢核完畢確認覆寫</label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button type="submit" className="btn btn-primary my-1">上傳</button>
                <PulseLoader loading={this.state.loading} color={"#999999"} size={6} height={6} margin={2} />
              </div>
            </div>
          </div>
        </form>
        <ExcelErrors errors={this.state.errors} />
      </div>
    )
  }
}

export default UploadExcel
import React from 'react'
import * as ImIcons from 'react-icons/im'; 

export const SideBarData = [
    {
        title: '課程管理',
        path: '/courses',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },
    {
        title: '培訓場次清單',
        path: '/classes',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },
    {
        title: '學員管理',
        path: '/users',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },
    {
        title: '區域清單',
        path: '/regions',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },
    {
        title: '學校管理',
        path: '/schools',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },
    {
        title: '城市/鄉鎮區',
        path: '/cities',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },    {
        title: '服務角色清單',
        path: '/serviceRoles',
        icon: <ImIcons.ImAddressBook/>,
        cName: 'nav-text'
    },
];

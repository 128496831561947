import http from "../http-common";

const getAll = () => {
  return http.get("/classes");
};

const get = (id) => {
  return http.get(`/classes/${id}`);
};

const create = (data) => {
  return http.post("/classes", data);
};

const update = (id, data) => {
  return http.put(`/classes/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/classes/${id}`);
};

const findByCourse = (courseCode) => {
  return http.get(`/classes?courseId=${courseCode}`);
};

const reportByClass = (classId) => {
  return http.get(`/UserClassReport?class=${classId}`);
};


// 後端 register 的 api 在 src/components/Registration/UploadExcel.js 呼叫，未移入 services/

const deRegister = (classId, userId) => {
  return http.post(`/classes/${classId}/deregister/${userId}`);
};

const ClassService = {
  getAll,
  get,
  create,
  update,
  remove,
  findByCourse,
  deRegister,
  reportByClass,
};

export default ClassService;

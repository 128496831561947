import React, { useState } from "react";
import {useLocation, useHistory} from "react-router-dom";
import CourseDataService from "../../services/CourseService";

const AddCourse = () => {
  const history = useHistory();
  const location = useLocation();

  const cacheTitle = location.state?.cacheTitle;
  const returnUrl = location.state?.returnUrl;
  
  const returnCourseLink = {
    pathname: returnUrl,
    state: {
      cacheTitle: cacheTitle
    }
  }

  const initialCourseState = {
    id: null,
    code: "",
    name: "",
    data: ""
  };
  
  const [tutorial, setCourse] = useState(initialCourseState);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCourse({ ...tutorial, [name]: value });
  };

  const returnLastPage = () => {
    history.push(returnCourseLink);
  };

  const saveCourse = () => {
    var data = {
      code: tutorial.code,
      name: tutorial.name,
      data: tutorial.data
    };

    CourseDataService.create(data)
      .then(response => {
        setCourse({
          id: response.data.id,
          code: response.data.code,
          name: response.data.name,
          data: response.data.data
        });
        setSubmitted(true);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    returnLastPage();
  };

  // const newCourse = () => {
  //   setCourse(initialCourseState);
  //   setSubmitted(false);
  // };

  return (
    <div className="submit-form">
      {submitted ? (
        {}
      ) : (
        <div>
          <div className="form-group">
            <label htmlFor="code">課程代碼</label>
            <input
              type="text"
              className="form-control"
              id="code"
              required
              value={tutorial.code}
              onChange={handleInputChange}
              name="code"
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">名稱</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={tutorial.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="data">資料</label>
            <textarea
              type="textarea"
              className="form-control"
              id="data"
			        rows={25}
              required
              value={tutorial.data}
              onChange={handleInputChange}
              name="data"
            />
          </div>

          <button onClick={saveCourse} className="btn btn-success">
            提交
          </button>
        </div>
      )}
    </div>
  );
};

export default AddCourse;

import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import UserDataService from "../../services/UserService";
import ClasseDataService from "../../services/ClassService";
import { useTable, usePagination } from "react-table";
import { useLocation } from "react-router-dom";
import { RegionsContext } from "../../App";
import UserClassReport from "./UserClassReport";
import PulseLoader from "react-spinners/PulseLoader";

import Excel from "exceljs";

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
};
  
const UsersList = (props) => {

  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchRegion, setSearchRegion] = useState("0");
  const UsersRef = useRef();
  const userIdRef = useRef(null);
  const location = useLocation();
  const classe = location.state?.class;
  const classId = (classe) ? classe.id : null;
  const { regions } = useContext(RegionsContext);

  UsersRef.current = users;

  useEffect(() => {
    retrieveUsers();
  }, [searchRegion, classId]);

  const retrieveUsers = () => {
    if(classId || searchTitle || searchRegion!="0") {
      setLoading(true);
      UserDataService.findByKeyword(searchTitle, classId, searchRegion).then(({data}) => {
        setUsers(data.total > 0 ? data.items:[]);
        setLoading(false);
      }).catch((e) => console.log(e));
    } else {
      setUsers([])
    }
  };

  const deRegisterClass = (rowIndex) => {
    if ( window.confirm("是否確認取消報名資格 ?") ){
      const userId = UsersRef.current[rowIndex].id;
      ClasseDataService.deRegister( classId, userId)
      .then((response) => {
        let newUsers = [...UsersRef.current];
        newUsers.splice(rowIndex, 1);
        setUsers(newUsers);
      })
      .catch((e) => {
        console.log(e);
      });
    } 
  }

  const exportExcel = () => {
    setLoading(true);
    const workbook = new Excel.Workbook();
    workbook.creator = "線上課程平台";
    workbook.lastModifiedBy = "線上課程平台";
    workbook.created = new Date();
    workbook.modified = new Date();
    var worksheet = workbook.addWorksheet("學員帳號資料");
    worksheet.columns = [
      { header: '#', key: 'id', width: 10 },
      { header: '姓名', key: 'name', width: 12 },
      { header: '帳號/電子郵件', key: 'email', width: 32 },
      { header: '主要電話', key: 'phone', width: 20 },
      { header: '市話或其他', key: 'altContact', width: 20 },
      { header: '備註/初次報名學校', key: 'note', width: 60 }
    ];
    users.forEach( x=> worksheet.addRow(x) );
    const row = worksheet.getRow(1);
    row.eachCell((cell, rowNumber) => {
      worksheet.getColumn(rowNumber).alignment = {
        vertical: "middle",
        horizontal: ([3,7,9].includes(rowNumber)) ? "left" : "center"
      };
    });
    worksheet.getRow(1).eachCell((cell, rowNumber) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(`${workbook.created.toISOString().substring(0,10)}.${classId ? "(#"+classId+")" : "" }.${searchRegion ? regions.find(x=>x.id ==searchRegion).name : "" }.學員清單匯出.xlsx`, buffer);
    });
    setLoading(false);
  }

  const writeFile = (fileName, content) => {
    let a = document.createElement("a");
    let blob = new Blob([content], { type: "text/plain" });
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.click();
  };

  const openUser = (userId) => {
    props.history.push("/users/" + userId);
  };

  const showUserClassReport = (userId) => {
    userIdRef.current = userId;
    setShowModal(true);
  };

  const toggleModal = (show) => {
    if( show == null ) 
      setShowModal(false);
    else 
      setShowModal(show);
  }

  const columns = useMemo(
    () => [
      {
        Header: "功能",
        accessor: "actions",
        Cell: (props) => {
          const rowIdx = props.row.id;
          return (
            <div>
              <span onClick={() => openUser(UsersRef.current[rowIdx].id)}>
                <i className="far fa-edit action mr-2"></i>
              </span>
              <span onClick={() => showUserClassReport(UsersRef.current[rowIdx].id)}>
                <i className='fa fa-list'></i>
              </span>  
              {(classId != null) ? 
                <span onClick={() => deRegisterClass(rowIdx)}>
                  <i style={{"color":"darkred"}} className="fas fa-user-times"></i>
                </span> : <></>}
            </div>
          );
        },
      },
      {
        Header: "姓名",
        accessor: "name",
      },
      {
        Header: "電子信箱",
        accessor: "email",
      },
      {
        Header: "手機",
        accessor: "phone",
      },
      {
        Header: "其他聯絡方式",
        accessor: "altContact",
      },
      {
        Header: "備註",
        accessor: "note",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePressEnter = (event) => {
    if (event.key === 'Enter') {
      retrieveUsers();
      console.log("Press Enter!");
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data: users,
    initialState: { pageSize: 10 },
  }, usePagination);

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-6 list">
          <h2>{(classId != null ? "培訓學員名單" : "學員管理" )} 
          <PulseLoader loading={loading} color={"#999999"} size={6} height={6} margin={2} /></h2>
        </div>
        <div className="col-md-6 list">
        { (classe) ? 
          <button 
            style={{float:'right'}} className="btn btn-link" 
            onClick={props.history.goBack}>
              {classe.name} 
              <i className="fas fa-angle-double-up"></i></button> : <></>}        
        </div>
      </div>
      <div className="list row align-items-end">
        <div className="col-md-4">
          <div className="form-group">
          <label>報名區域：</label>
            <select className="form-control"
              name="regionId"
              value={searchRegion}
              onChange={e=>setSearchRegion(e.target.value)}>
              {
                (regions) ? [<option key="0" value="0">--</option>].concat(
                  regions.sort((a,b)=> { return (a.id > b.id) ? 1 : (a.id == b.id) ? 0 : -1 }).map(x => <option key={x.id} value={x.id}>{x.name}</option>))
                  : <></>
              }
            </select>  
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>關鍵字搜尋：</label>
            <input autoFocus 
              type="text"
              className="form-control"
              placeholder="姓名、手機、電子信箱"
              value={searchTitle}
              onChange={e=>setSearchTitle(e.target.value)}
              onKeyPress={handlePressEnter}
            />
          </div>
        </div>
        <div className="align-bottom col-2">
          <div className="form-group">
          <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={retrieveUsers}>搜尋</button>
          </div>
        </div>
      </div>
      <div className="list row top10">
      <div className="col-md-12 list">
      { (users.length > 0) ? 
        <table
          className="table table-striped table-bordered"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table> : <></>
      }
      { (users.length > 0) ?
          <div className="d-flex justify-content-around align-items-center" style={{height: 80}}>
            <div className="pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}> {'<<'} </button>{' '}
              <button onClick={() => previousPage()} disabled={!canPreviousPage}> {'<'} </button>{' '}
              <button onClick={() => nextPage()} disabled={!canNextPage}> {'>'} </button>{' '}
              <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}> {'>>'} </button>{' '}
              <span> 第{' '} <strong> {pageIndex + 1} / {pageOptions.length} </strong>{' '} 頁 </span>
              <span>
                | 跳至頁數:{' '}
                <input
                  type="number"
                  min="1"
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px' }}
                />
              </span>{' '}
              <select
                value={pageSize}
                onChange={e => { setPageSize(Number(e.target.value)) }}
              >
                {[10, 20, 50, 100].map(pageSize => ( <option key={pageSize} value={pageSize}> 每頁 {pageSize} 筆 </option> ))}
              </select>
              <span> 共{' '} <strong>{users.length}</strong>{' '} 筆 </span> {' '} 
            </div>
            <button onClick={() => exportExcel()}> 匯出 Excel </button>
          </div> : 
         <EmptyNotice classId={classId} />
      }
        </div>
      </div>
      <UserClassReport 
        classId={classId} userId={userIdRef.current} users={users}
        show={showModal} toggleHnd={toggleModal}></UserClassReport>
    </div>
  );
};

const EmptyNotice = (props) => {
  return ( props.classId == null ) ? 
    <div>
      <h4>請輸入查詢條件後點擊 [搜尋]</h4>
    </div>
    :  <h4>目前課程無學員報名</h4>
}

export default UsersList;

import axios from "axios";

export default axios.create({
  // baseURL: "http://localhost:5001/api",
  baseURL: "/api",
  headers: {
    "Content-type": "application/json"
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  }, function (error) {
  if (401 === error.response.status) {
    window.location = '/authentication/login';
  } else {
    return Promise.reject(error);
  }
  });

  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import CoursesDataService from "../../services/CourseService";
import { useTable } from "react-table";

const CoursesList = (props) => {

  const [Courses, setCourses] = useState([]);
  const CoursesRef = useRef();
  
  CoursesRef.current = Courses;

  useEffect(() => {
    retrieveCourses();
  }, []);

  
  const retrieveCourses = () => {
    CoursesDataService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addCourseLink = {
    pathname: '/add/course',
    state: { 
      returnUrl: '/courses'
    }
  };

  const openTutorial = (rowIndex) => {
    const id = CoursesRef.current[rowIndex].id;
    props.history.push({pathname: "/courses/" + id});
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",        
      },
      {
        Header: "課程代碼",
        accessor: "code",
      },
      {
        Header: "名稱",
        accessor: "name",
      },
      {
        Header: "資料",
        accessor: "data",
        Cell: ({value}) => { 
          try { 
            let course = JSON.parse(value);
            return <div style={{fontSize:'10px', height:150, overflowY:'scroll'}}>
              { course.chapters.map( 
                (chapter, idx_chapter) => <div key={idx_chapter}>
                  {chapter.name}
                  <ul>
                  {chapter.sections.map( (section, idx_s) => 
                    <li key={idx_s}>{section.name}
                      <ul>{section.videos.map( (v,idx_v) => <li key={idx_v}>{v.title}</li> )}</ul>
                    </li>
                  )}
                  </ul>
                </div> 
              )}
            </div>;
          } catch (err){
            console.log(err);
            return <>課程格式錯誤</>
          }
        }
      },
      {
        Header: "功能",
        accessor: "actions",
        Cell: ({row}) => {
          const rowIdx = row.id;
          return (
            <div className="actions">
              <div onClick={() => openTutorial(rowIdx)}>
                <button><i className="far fa-edit action mr-2"></i> 編輯課程資訊</button>
              </div>
              <Link
                to={{
                  pathname: "/classes",
                  search: "?course="+row.original.id,
                  state: { course: row.original, returnUrl: '/courses' }
                }}>
                  <button><i className="far fa-list-alt" title="培訓場清單"></i> 培訓場清單</button>
              </Link>
              <Link
                to={{
                  pathname: "/courses/"+row.original.id+"/classes",
                  state: { course: row.original }
                }}><button className="add"><i className="far fa-plus-square action mr-2"></i> 建立培訓場</button>
              </Link>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: Courses,
  });

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-8 list">
          <h2>課程管理</h2>
        </div>
        <div className="col-md-4 list">
        <Link to={addCourseLink}>
            <button className="btn btn-success">
            <i className="far fa-plus-square action mr-2"></i> 新增課程
            </button>
        </Link>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-12 list">
          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CoursesList);

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CourseDataService from "../../services/CourseService";

const Course = (props) => {
  const cacheTitle = props.location.state?.cacheTitle ;
  console.log(`Last Info - props ${props.location.state?.cacheTitle}`);

  const initialCourseState = {
    id: null,
    code: "",
    name: "",
    data: ""
  };
  const [currentCourse, setCurrentCourse] = useState(initialCourseState);
  const [message, setMessage] = useState("");

  const getCourse = id => {
    CourseDataService.get(id)
      .then(response => {
        setCurrentCourse(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getCourse(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentCourse({ ...currentCourse, [name]: value });
  };

  const updateCourse = () => {
    CourseDataService.update(currentCourse.id, currentCourse)
      .then(response => {
        console.log(response.data);
        setMessage("課程內容更新！");
        props.history.push({
          pathname: "/courses",
          state: {
            cacheTitle: cacheTitle
          }
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div>
      {currentCourse ? (
        <div className="edit-form">
          <h4>課程</h4>
          <form>
            <div className="form-group">
              <label htmlFor="code">課程代碼</label>
              <input
                type="text"
                className="form-control"
                id="code"
                name="code"
                value={currentCourse.code}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">名稱</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={currentCourse.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="data">課程詳細內容 (由工程師整理提供)</label>
              <textarea
                type="textarea"
                className="form-control"
                id="data"
                rows={25}
                required
                style={{whiteSpace:'pre-line'}}
                value={currentCourse.data}
                onChange={handleInputChange}
                name="data"
              />
            </div>

          </form>

          <button
            type="submit"
            className="btn btn-success"
            onClick={updateCourse}
          >
            更新
          </button>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          {/* <p>Please click on a Course...</p> */}
        </div>
      )}
    </div>
  );
};

export default withRouter(Course);

import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import ServiceRoleDataService from "../../services/ServiceRoleService";
import { useTable } from "react-table";

const ServiceRoleList = (props) => {
  const location = useLocation();
  const cacheTitle = props.location.state?.cacheTitle || location.state?.cacheTitle;

  const [ServiceRole, setServiceRole] = useState([]);
  const ServiceRoleRef = useRef();

  ServiceRoleRef.current = ServiceRole;

  useEffect(() => {
    retrieveServiceRole();
  }, []);

  const retrieveServiceRole = () => {
    ServiceRoleDataService.getAll()
      .then((response) => {
        setServiceRole(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addServiceRoleLink = {
    pathname: '/add/serviceRole',
    state: {
      returnUrl: '/serviceRoles'
    }
  };

  const openServiceRole = (rowIndex) => {
    const id = ServiceRoleRef.current[rowIndex].id;
    props.history.push({ pathname: "/serviceRoles/" + id });
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "名稱",
        accessor: "name",
      },
      {
        Header: "功能",
        accessor: "actions",
        Cell: ({ row }) => {
          const rowIdx = row.id;
          return (
            <div className="actions">
              <div onClick={() => openServiceRole(rowIdx)}>
                <button><i className="far fa-edit action mr-2"></i> 編輯</button>
              </div>

            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: ServiceRole,
  });

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-6 list">
          <h2>服務角色清單</h2>
        </div>
        <div className="col-md-2 list">
          <Link to={addServiceRoleLink}>
            <button className="btn btn-success">
              <i className="far fa-plus-square action mr-2"></i> 新增
            </button>
          </Link>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-8 list">
          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};


export default withRouter(ServiceRoleList);

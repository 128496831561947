import http from "../http-common";

const getAll = () => {
  return http.get("/Courses");
};

const get = (id) => {
  return http.get(`/Courses/${id}`);
};

const create = (data) => {
  return http.post("/Courses", data);
};

const update = (id, data) => {
  return http.put(`/Courses/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/Courses/${id}`);
};

const findByTitle = (courseCode) => {
  return http.get(`/Courses?courseCode=${courseCode}`);
};

const CourseService = {
  getAll,
  get,
  create,
  update,
  remove,
  findByTitle,
};

export default CourseService;

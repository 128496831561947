import React, { useState } from "react";
import ClassDataService from "../../services/ClassService";

const AddClass = (props) => {

  const initialClasseState = (props.location.state) ? {
    id: null,
    name: "",
    startDate: "",
    endDate: "",
    courseId: props.location.state.course.id,
    courseData: props.location.state.course.data,
    agreementData: "{}",
    note: "課程：" + props.location.state.course.name 
  } : {
    id: null,
    name: "",
    startDate: "",
    endDate: "",
    courseId: "",
    courseData: "",
    agreementData: "{}",
    note: ""
  };
  const [classe, setClasse] = useState(initialClasseState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setClasse({ ...classe, [name]: value });
  };

  const saveClasse = () => {
    var data = {
      name: classe.name,
      startDate: classe.startDate,
      endDate: classe.endDate,
      courseId: classe.courseId,
      courseData: classe.courseData,
      agreementData: classe.agreementData,
      note: classe.note
    };

    ClassDataService.create(data)
      .then(response => {
        setClasse({
          id: response.data.id,
          name: response.data.name,
          startDate: response.data.startDate,
          endDate: response.data.endDate,
          courseData: response.data.courseData,
          agreementData: response.data.agreementData,
          note: response.data.note,
        });
        console.log(response.data);
        props.history.goBack();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div className="submit-form">
      {
        <div>

          <div className="form-group">
            <label htmlFor="name">名稱</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={classe.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="startDate">起 (格式：YYYY-MM-DD<b style={{"color":"red"}}>T</b>hh:mm，如： 2021-10-10 或 2021-10-10T12:00)</label>
            <input
              type="text"
              className="form-control"
              id="startDate"
              required
              value={classe.startDate}
              onChange={handleInputChange}
              name="startDate"
            />
          </div>

          <div className="form-group">
            <label htmlFor="endDate">迄 (格式：YYYY-MM-DD<b style={{"color":"red"}}>T</b>hh:mm，如： 2021-10-10 或 2021-10-10T12:00)</label>
            <input
              type="text"
              className="form-control"
              id="endDate"
              required
              value={classe.endDate}
              onChange={handleInputChange}
              name="endDate"
            />
          </div>

          <div className="form-group">
            <label htmlFor="courseData">課程編號 (系統自動帶入)</label>
            <input
              type="text"
              className="form-control"
              id="courseId"
              required disabled
              value={classe.courseId}
              name="courseId"
            />
          </div>

          <div className="form-group">
            <label htmlFor="courseData">課程內容</label>
            <textarea
              type="textarea"
              className="form-control"
              id="courseData"
      			  rows={10}
              style={{fontSize:'10px'}}
              required
              value={classe.courseData}
              onChange={handleInputChange}
              name="courseData"
            />
          </div>

          <div className="form-group">
            <label htmlFor="agreementData">同意書</label>
            <input
              type="text"
              className="form-control"
              id="agreementData"
              required
              value={classe.agreementData}
              onChange={handleInputChange}
              name="agreementData"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="note">備註</label>
            <input
              type="text"
              className="form-control"
              id="note"
              required
              value={classe.note}
              onChange={handleInputChange}
              name="note"
            />
          </div>
          
          <button onClick={saveClasse} className="btn btn-success" style={{marginTop:'12px'}}>
            新增培訓場
          </button>
        </div>
      }
    </div>
  );
};

export default AddClass;

import React, { useState, useEffect } from "react";
import ClassDataService from "../../services/ClassService";

const Class = props => {
  const initialClassState = {
    id: null,
    title: "",
    startDate: "",
    endDate: "",
    courseData: "{}",
    courseId: "",
    agreementData: "{}",
    note: ""
  };
  const [currentClass, setCurrentClass] = useState(initialClassState);
  const [message, setMessage] = useState("");

  const getClass = id => {
    ClassDataService.get(id)
      .then(response => {
        setCurrentClass(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getClass(props.match.params.id);
  }, [props.match.params.id]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentClass({ ...currentClass, [name]: value });
  };

  const updateClass = () => {
    ClassDataService.update(currentClass.id, currentClass)
      .then(response => {
        setMessage("培訓內容更新！");
        props.history.goBack();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div>
      {currentClass ? (
        <div className="edit-form">
          <h4>培訓場次</h4>
          <form>
          <div className="form-group">
            <label htmlFor="name">名稱</label>
            <input
              type="text"
              className="form-control"
              id="name"
              required
              value={currentClass.name}
              onChange={handleInputChange}
              name="name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="startDate">影片上架時間 (格式：YYYY-MM-DD<b style={{"color":"red"}}>T</b>hh:mm)</label>
            <input
              type="text"
              className="form-control"
              id="startDate"
              required
              value={currentClass.startDate}
              onChange={handleInputChange}
              name="startDate"
            />
          </div>

          <div className="form-group">
            <label htmlFor="endDate">影片下架時間 (格式：YYYY-MM-DD<b style={{"color":"red"}}>T</b>hh:mm)</label>
            <input
              type="text"
              className="form-control"
              id="endDate"
              required
              value={currentClass.endDate}
              onChange={handleInputChange}
              name="endDate"
            />
          </div>

          <div className="form-group">
            <label htmlFor="courseData">課程編號 (系統自動帶入)</label>
            <input
              type="text"
              className="form-control"
              id="courseId"
              required disabled
              value={currentClass.courseId}
              name="courseId"
            />
          </div>

          <div className="form-group">
            <label htmlFor="courseData">課程內容</label>
            <textarea
              type="textarea"
              className="form-control"
              id="courseData"
      			  rows={20}
              style={{fontSize:'10px'}}
              required
              value={currentClass.courseData}
              onChange={handleInputChange}
              name="courseData"
            />
          </div>

          <div className="form-group">
            <label htmlFor="agreementData">同意書</label>
            <textarea
              type="textarea"
              className="form-control"
              id="agreementData"
              required
              rows={20}
              style={{fontSize:'10px'}}
              value={currentClass.agreementData}
              onChange={handleInputChange}
              name="agreementData"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="note">備註</label>
            <input
              type="text"
              className="form-control"
              id="note"
              required
              value={currentClass.note}
              onChange={handleInputChange}
              name="note"
            />
          </div>

          </form>

          <button
            type="submit"
            className="btn btn-success"
            onClick={updateClass}
          >
            更新
          </button>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          {/* <p>Please click on a Class...</p> */}
        </div>
      )}
    </div>
  );
};

export default Class;

import ClasseDataService from "../../services/ClassService";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTable } from "react-table";

import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";

import Excel from "exceljs";

const writeFile = (fileName, content) => {
  let a = document.createElement("a");
  let blob = new Blob([content], { type: "text/plain" });
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.click();
};

const ClasseList = (props) => {

  const [Classes, setClassList] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const returnUrl = location.state?.returnUrl;
  const course = location.state?.course

  const returnLink = {
    pathname: returnUrl
  }

  const returnLastPage = () => {
    history.push(returnLink);
  }

  const ClasseRef = useRef();
  
  ClasseRef.current = Classes;

  useEffect(() => {
    retrieveClasse();
  }, [course]);

  const retrieveClasse = () => {
    ClasseDataService.getAll()
      .then((response) => {
        setClassList((course) ? response.data.filter( x => x.courseId === course.id ) : response.data );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toggleView = () => {
    setShowAll(!showAll);
  };

  const editClass = (rowIndex) => {
    const id = ClasseRef.current[rowIndex].id;
    props.history.push("/classes/" + id);
  };

  const uploadRegistrationData = (rowIndex) => {
    const id = ClasseRef.current[rowIndex].id;
    props.history.push("/classes/" + id + "/registrations");
  }

  function getDate(value){
    return ( value ) ? (""+value).substring(0,10) : "N/A";
  }

  const exportExcel = (classId) => {

    const workbook = new Excel.Workbook();
    setLoading(true);
    ClasseDataService.reportByClass(classId).then(({ data }) => {
      var currentTime = new Date();
      var className = (data.length >0) ? data[0].className : "";
      workbook.creator = "線上課程平台";
      workbook.lastModifiedBy = "線上課程平台";
      workbook.created = currentTime ;
      workbook.modified = currentTime;
      var worksheet = workbook.addWorksheet("網路觀課狀況", { views: [{ state: 'frozen', ySplit: 1 }] });
      worksheet.columns = [
        { header: '培訓場', key: 'className', width: 40 },
        { header: '報名區域', key: 'registrationRegion', width: 12 },
        { header: '報名學校', key: 'schoolName', width: 20 },
        { header: '學員系統編號', key: 'userId', width: 12 },
        { header: '學員', key: 'realName', width: 20 },
        { header: '影片系統編號', key: 'videoId', width: 12 },
        { header: '完畢', key: 'completion', width: 5 },
        { header: '影片', key: 'videoTitle', width: 60 }
      ];
      const colUserId = worksheet.getColumn(4);
      colUserId.hidden = true;

      const colVideoId = worksheet.getColumn(6);
      colVideoId.hidden = true;

      var prevSectionId = (data.length > 0) ? data[0].realName : "";

      const color1 = 'FFFFFF'; // white background
      const color2 = 'DEE2E6'; // grey background
      const color3 = 'FFE0E0'; // ping

      var sectionBgColor = color2;
      // 填入資料
      data.forEach(x => {
        var currentRow = worksheet.addRow(x)
        if (prevSectionId !== x.realName) {
          sectionBgColor = (sectionBgColor === color2) ? color1 : color2;
          prevSectionId = x.realName;
        }
        currentRow.eachCell((cell, rowNumber) => {
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: sectionBgColor } };
        });
        if (x.completion === 0) {
          currentRow.getCell('completion').value = "";
          currentRow.getCell('completion').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: color3 } };
        } else {
          currentRow.getCell('completion').value = "是";
        }
      });
      const row = worksheet.getRow(1);
      row.eachCell((cell, rowNumber) => {
        worksheet.getColumn(rowNumber).alignment = {
          vertical: "middle",
          horizontal: ([8].includes(rowNumber)) ? "left" : "center"
        };
      });
      worksheet.getRow(1).eachCell((cell, rowNumber) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        writeFile(`${new Date(currentTime.getTime() - (currentTime.getTimezoneOffset() * 60000)).toISOString().substring(0, 16).replace(" ", "_")}.${className}.觀課紀錄匯出.xlsx`, buffer);
      });
      setLoading(false);
    }).catch((e) =>{ console.log(e) });
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",        
      },
      {
        Header: "名稱",
        accessor: "name",
      },
      {
        Header: "影片上架/下架時間",
        accessor: d => (
          <div 
            className="wrapper" 
            style={ (!isActive(d.endDate)) ? {color:'#aaa'} : {} }>
            <div style={{whiteSpace:'nowrap'}}>{getDate(d.startDate)}</div>
            <div>{getDate(d.endDate)}</div>
          </div>
		    )
      },
      {
        Header: "課程資料",
        accessor: "courseData",
        Cell: (props) => {
          try { 
            let course = JSON.parse(props.value);
            return <div style={{fontSize:'10px', height:150, overflowY:'scroll'}}>
              { course.chapters.map( 
                (chapter, idx_chapter) => <div key={idx_chapter}>
                  {chapter.name}
                  <ul>
                  {chapter.sections.map( (section, idx_s) => 
                    <li key={idx_s}>{section.name} - {section.videos.length} 部影片</li>
                  )}
                  </ul>
                </div> 
              )}
            </div>;
          } catch (err){
            console.log(err);
            return <>課程格式錯誤</>
          }
        }
      },
      {
        Header: "備註",
        accessor: "note",
      },
      {
        Header: "功能",
        accessor: "actions",
        Cell: (props) => {
          const rowIdx = props.row.id;
          return (
          <div className="actions">
            <button onClick={() => editClass(rowIdx)}>
              <i className="far fa-edit action mr-2"></i> 編輯設定
            </button>
            <div onClick={() => uploadRegistrationData(rowIdx)}>
            <button className="add"><i className="fas fa-upload action mr-2"></i> 匯入報名檔</button>
            </div>
            <Link
              to={{
                pathname: "/users",
                search: "?classId="+props.row.original.id,
                state: { class: props.row.original, returnUrl: '/courses' }
              }}><button><i className="fas fa-users action mr-2"></i> 培訓學員</button>
              </Link>
              <div onClick={() => exportExcel(ClasseRef.current[rowIdx].id)}>
                <button>
                <i className='fa fa-download action mr-2'></i> 觀課報表</button>
              </div>
          </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isActive = ( endDateStr ) => {
    if( endDateStr === null )
      return true;
    else {
      var endDate = new Date(endDateStr);
      var now = new Date();
      if( endDate > now ) return true;
    }
    return false;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: Classes.filter( x => (showAll) ? true : isActive(x.endDate) ),
  });

  const override = css`
  margin: 0 auto;
  `;

  return (
    <div>
      <div className="list row top10">
        <div className="col-md-6 list">
          <h2>培訓場次清單 <PulseLoader loading={loading} color={"#999999"} css={override} size={6} height={6} margin={2} /> </h2>
          <label>顯示已結束場次：<input type="checkbox"
            onChange={toggleView} checked={showAll} /></label>
        </div>
        <div className="col-md-6 list">
        {(course) ? 
          <button 
            style={{float:'right'}} 
            className="btn btn-link" onClick={returnLastPage}>
            課程 {course.code} &nbsp;
            <i className="fas fa-angle-double-up"></i>
          </button> : <></>}
        </div>
      </div>
      <div className="list row">
        <div className="col-md-12 list">
          <table
            className="table table-striped table-bordered"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClasseList;

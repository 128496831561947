import React from 'react';
import { DistrictsContext } from '../../App';
import './style.css';

export default function DistrictsCell( {cityId} ) {
    let { districts } = React.useContext(DistrictsContext);
    if( districts ){ 
        return <>
        {
            districts.filter(x=> { return x.cityId === cityId } ).map(
                x => <span key={x.id} className="district">{x.name}</span>
            )
        }
        </>;
    }
    return <>{cityId}</>;
 } 
import React, { useState, useEffect } from "react";
import UserDataService from "../../services/UserService";

import { Modal, Accordion } from 'react-bootstrap';
import Excel from 'exceljs';


const UserClassReport = ({ users, userId, classId, show, toggleHnd }) => {

    const [reportData, setReportData] = useState({});

    const userCandidates = users.filter(x =>x.id === userId);
    const userName = (userCandidates.length === 1) ? userCandidates[0].name : "";

    useEffect(() => {
      if (!show) {
        setReportData({});
      }
    }, [show]);
    
    useEffect(() => {
        if(userId!= null)
        getUserReport(userId, classId);
    }, [userId, classId]);
    
    const exportExcel = () => {
      const workbook = new Excel.Workbook();
      workbook.creator = "線上課程平台";
      workbook.lastModifiedBy = "線上課程平台";
      workbook.created = new Date();
      workbook.modified = new Date();
      var timestamp = new Date().toISOString().substring(0,10)
      var worksheet = workbook.addWorksheet(userName + `觀課紀錄(${timestamp})`);
      worksheet.columns = [
        { header: '報名區域', key: 'region', width: 10 },
        { header: '報名學校', key: 'school', width: 30 },
        { header: '培訓場', key: 'className', width: 36 },
        { header: '影片', key: 'videoTitle', width: 60 },
        { header: '完畢', key: 'isComplete', width: 12 }
      ];

      Object.keys(reportData).forEach( c=> {
        const entry = reportData[c];
        entry.videos.forEach( x=> worksheet.addRow({ 'className' : entry.className, 'region':entry.region, 'school': entry.school, 'isComplete': (x.completion===1) ? "是" :" ", ...x}) );
      });

      const row = worksheet.getRow(1);
      row.eachCell((cell, rowNumber) => {
        worksheet.getColumn(rowNumber).alignment = {
          vertical: "middle",
          horizontal: ([3,4].includes(rowNumber)) ? "left" : "center"
        };
      });
      worksheet.getRow(1).eachCell((cell, rowNumber) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        writeFile(`${timestamp}.${userName}(#${userId}) 觀課紀錄.xlsx`, buffer);
      });
    }

    const writeFile = (fileName, content) => {
      let a = document.createElement("a");
      let blob = new Blob([content], { type: "text/plain" });
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.click();
    };
  
    const getUserReport = (userId, classId) => {
          UserDataService.reportByUserClass(userId, classId).then(({data}) => {
           var i = 0;
           const report = data.reduce(
            (r, item) => {
              const { classId, className, videoId, videoTitle, completion, registrationRegion, schoolName } = item;
              if( r[classId] ){
                r[classId].videos.push(
                  {videoId: videoId, videoTitle: videoTitle, completion: completion}
                );
              } else {
                r[classId] = {
                  className: className,
                  classId: classId,
                  region: registrationRegion,
                  school: schoolName,
                  videos: [{videoId: videoId, videoTitle: videoTitle, completion: completion}]
                }
              }
              return r;
            }, {}
          )
          console.log(report);
          setReportData(report);
         });
    };
    
    return <Modal
      size="lg"
      show={show}
      onHide={() => toggleHnd(false)}
      aria-labelledby="modal-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">
          {userName} 觀課紀錄
          <span style={{color:"#ccc", fontSize:"11px"}}>資料時間：{(new Date()).toLocaleDateString()}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey={ reportData.slice ? reportData.slice(0, 1).shift().classId : "" }>
          {Object.keys(reportData).reverse().map( (c, c_idx) => 
          <Accordion.Item eventKey={reportData[c].classId} key={reportData[c].classId}>
            <Accordion.Header>
            {reportData[c].className} 
            <small style={{fontSize:"11px"}}>
              &nbsp;&nbsp;&nbsp;{reportData[c].region ? reportData[c].region : "?"}-{reportData[c].school}
              &nbsp;進度 ({reportData[c].videos.filter(x=>x.completion===1).length}/{reportData[c].videos.length})</small></Accordion.Header>
            <Accordion.Body>
              {reportData[c].region} {reportData[c].school} 
              <ul style={{fontSize:"11px"}}>
                { reportData[c].videos.map( (v,i) => <li key={i} style={{color:(v.completion===0)?"#eaa":"black"}}>{v.videoTitle} <span style={{color:"white"}}>#{v.videoId}</span></li> ) }
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          )}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => exportExcel()}> 匯出 Excel </button>
      </Modal.Footer>
    </Modal>;
};

export default UserClassReport;